import React, { useEffect, useState } from 'react';
import { Button, mergeClassNames, toast, Tooltip } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import CopyIcon from '@v2/assets/icons/copy-icon.svg';

export enum CopyButtonFeedback {
    TOAST = 'toast',
    TOOLTIP = 'tooltip',
    NONE = 'none'
}

export interface CopyButtonProps {
    textToCopy: string,
    feedbackType: CopyButtonFeedback,
    successMessage?: string,
    errorMessage?: string,
    buttonClassName?: string,
    iconClassName?: string,
}

export const CopyButton : React.FC<CopyButtonProps> = ({ textToCopy, feedbackType, successMessage, errorMessage, buttonClassName, iconClassName }) => {
    const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
    const [tooltipMessage, setTooltipMessage] = useState('');
    const successMessageText = successMessage ?? 'Copied';
    const errorMessageText = errorMessage ?? 'Error - Not copied';

    useEffect(() => {
        if (shouldShowTooltip) {
            const timer = setTimeout(() => {
                setShouldShowTooltip(false);
            }, 3000);

            return () => { clearTimeout(timer); };
        }
        return () => {};
    }, [shouldShowTooltip]);

    const handleClickCopyButton = async () => {
        try {
            await navigator.clipboard.writeText(textToCopy).then(() => {
                switch (feedbackType) {
                    case CopyButtonFeedback.TOAST:
                        toast.show({
                            message: successMessageText,
                            icon: <CheckIcon />,
                            showClose: false,
                            duration: 4000
                        });
                        break;
                    case CopyButtonFeedback.TOOLTIP:
                        setTooltipMessage(successMessageText);
                        setShouldShowTooltip(true);
                        break;
                    case CopyButtonFeedback.NONE:
                    default:
                        break;
                }
            });
        } catch (e) {
            switch (feedbackType) {
                case CopyButtonFeedback.TOAST:
                    toast.show({ message: errorMessageText });
                    break;
                case CopyButtonFeedback.TOOLTIP:
                    setTooltipMessage(errorMessageText);
                    setShouldShowTooltip(true);
                    break;
                case CopyButtonFeedback.NONE:
                default:
                    break;
            }
        }
    };

    return (
        <Tooltip trigger={
            <Button className={buttonClassName} variant="ICON" size="ICON_SMALL" onClick={handleClickCopyButton}>
                <CopyIcon className={mergeClassNames('mt-1 h-4 w-4 flex-none', iconClassName)} />
            </Button>
        }
        show={shouldShowTooltip}
        placement="top"
        showArrow={false}
        offset={[0, 3]}>
            {tooltipMessage}
        </Tooltip>
    );
};
