import '../../../styles/dataPage.scss';
import React, { useEffect, useMemo } from 'react';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Loading, NotchDataGrid, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { ARRoutePaths } from '@v2/constants/EPaths';
import BackIcon from '@icons/back-icon.svg';
import SearchIcon from '@icons/search-icon.svg';
import { PageTitle } from '@ar/components/PageTitle';
import { InvoiceStatusBadge } from '@ar/components/Invoices/InvoiceStatusBadge';
import { DraftInvoice,
    TransactionStatus,
    OptionalAddress,
    DraftInvoiceItem,
    PatchDraftInvoiceBody,
    PatchDraftInvoiceLineItemData,
    publishDraftInvoice, republishDraftInvoice } from '@ar/network/AccountsReceivable.network';
import { SeparatorInternalPageFullWidth } from '@ar/components/shared/SeparatorInternalPageFullWidth';
import { SectionTitle } from '@ar/components/shared/SectionTitle';
import { draftInvoiceValidationSchema, EmptyState, getInvoiceItemColumns, gridSX, PanelBlock } from '@ar/pages/DraftInvoiceDetailsPage/DraftInvoiceDetailsPageConstants';
import { useGetCustomer } from '@ar/hooks/queries/CustomerQueries.hook';
import { usePublishOrRepublishDraftInvoiceMutation, useSaveDraftInvoiceMutation } from '@ar/hooks/mutations/DraftInvoiceMutations';
import { getGpoId } from '@v2/utils/GPOUtils';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { DatePickerInput } from '@v2/components/Shared/DatePickerInput/DatePickerInput';
import AddIcon from '@icons/add-icon.svg';
import { CurrencyInput } from '@v2/components/Shared/InputCurrency';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { centsToDollars, dollarsToCents, formatAsCurrency } from '@/utils/Formatters';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { CopyButton, CopyButtonFeedback } from '@/ar/components/shared/CopyButton';
import { DraftInvoiceItemFormModal } from '@/ar/components/DraftInvoiceDetails/DraftInvoiceItemFormModal';
import { DraftInvoiceModals, useDraftInvoiceStore } from '@/ar/stores/DraftInvoiceStore';
import { DraftInvoiceItemDeleteModal } from '@/ar/components/DraftInvoiceDetails/DraftInvoiceItemDeleteModal';
import { useGetDraftInvoice } from '@/ar/hooks/queries/DraftInvoicesQueries.hook';
import { CustomerOption, CustomerSearch } from './CustomerSearch';
import { CustomerAddress } from './CustomerAddress';
import { useKeyCombination } from '@/ar/hooks/useKeyCombination';
import { FeatureFlags } from '@/constants/FeatureFlags';

type CustomerInfo = {
    name: string,
    address: OptionalAddress,
};

const DEFAULT_CUSTOMER_INFO: CustomerInfo = {
    name: '',
    address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        postal_code: ''
    }
};

type DraftInvoiceFormData = {
    customer_id?: string | null,
    invoice_number?: string | null,
    invoice_date?: string | null,
    due_date?: string | null,
    invoice_total?: number | null,
    offline_credits_payment?: number | null,
    outstanding_balance?: number | null,
    line_items?: DraftInvoiceItem[],
};

const calculateInvoiceTotalsByItems = (items: DraftInvoiceItem[] = []) => {
    const subtotal = items.reduce((acc, item) => acc + (item.price * item.quantity), 0);
    const taxAmount = items.reduce((acc, item) => acc + item.tax_amount, 0);
    const total = subtotal + taxAmount;
    return {
        subtotal: centsToDollars(subtotal),
        taxAmount: centsToDollars(taxAmount),
        total: centsToDollars(total)
    };
};

const mapLineItemsToPatchBody = (lineItems: DraftInvoiceItem[]): PatchDraftInvoiceLineItemData[] => {
    if (!lineItems || lineItems.length === 0) return [];

    return lineItems.map((lineItem) => ({
        id: lineItem.id,
        description: lineItem.description,
        price: lineItem.price,
        quantity: lineItem.quantity,
        tax_amount: lineItem.tax_amount,
        total: lineItem.total,
    }));
};

const valueFormatters = {
    invoice_total: dollarsToCents,
    offline_credits_payment: dollarsToCents,
    outstanding_balance: dollarsToCents,
    line_items: mapLineItemsToPatchBody,
};

const mapUpdatedFieldsToPatchBody = (updatedFields: Partial<DraftInvoiceFormData>): Partial<PatchDraftInvoiceBody> => (
    Object.entries(updatedFields).reduce((acc, [invoiceField, value]) => {
        if (value !== undefined) {
            if (valueFormatters[invoiceField]) {
                acc[invoiceField] = valueFormatters[invoiceField](value);
            } else {
                acc[invoiceField] = value;
            }
        }

        return acc;
    }, {})
);

const areLineItemsEqual = (lineItemA: DraftInvoiceItem, lineItemB: DraftInvoiceItem) => {
    const keysToCompare = ['total', 'price', 'quantity', 'tax_amount', 'description', 'id'];

    return keysToCompare.every((key) => lineItemA[key] === lineItemB[key]);
};

const areLineItemsChanged = (originalLineItems: DraftInvoiceItem[], lineItems: DraftInvoiceItem[]): boolean => {
    if (originalLineItems.length !== lineItems.length) return true;

    return lineItems.some((lineItem) => {
        const originalLineItem = originalLineItems.find((item) => item.id === lineItem.id);
        return !areLineItemsEqual(originalLineItem, lineItem);
    });
};

const filterUpdatedFields = (originalInvoice: DraftInvoiceFormData, draftInvoice: DraftInvoiceFormData): Partial<DraftInvoiceFormData> => {
    const keysToCompare = Object.keys(originalInvoice).filter((key) => key !== 'line_items');

    const updatedFields: Partial<DraftInvoiceFormData> = keysToCompare.reduce((acc, key) => {
        if (originalInvoice[key] !== draftInvoice[key]) {
            acc[key] = draftInvoice[key];
        }
        return acc;
    }, {});

    if (areLineItemsChanged(originalInvoice.line_items, draftInvoice.line_items)) {
        updatedFields.line_items = draftInvoice.line_items;
    }

    return updatedFields;
};

/**
 * The AR Draft Invoices detail page
 *
 * @returns JSX Element
 */
export const DraftInvoiceDetailsPage = function DraftInvoiceDetailsPage(): JSX.Element {
    useDocumentTitle('Draft Invoice Details - Notch');
    const navigate = useNavigate();
    const flags = useFlags<FeatureFlags>();
    const { supplierLoginData } = useSupplierStore();
    const { openModal } = useDraftInvoiceStore();
    const gpoId = getGpoId(supplierLoginData);
    const { invoiceID } = useParams();
    const [showCustomerSearchInput, setShowCustomerSearchInput] = React.useState<boolean>(false);
    const [customerInfo, setCustomerInfo] = React.useState<CustomerInfo>(DEFAULT_CUSTOMER_INFO);
    const saveInvoiceMutation = useSaveDraftInvoiceMutation();
    const lineItemsFeatureFlagEnabled = flags.arInvoiceEditorLineItems;

    const mapInvoiceValuesToForm = (invoice: DraftInvoice): Partial<DraftInvoice> => ({
        customer_id: invoice.customer_id,
        invoice_number: invoice.invoice_number,
        invoice_date: invoice?.invoice_date ? new Date(invoice?.invoice_date).toISOString().split('T')[0] : '',
        due_date: invoice?.due_date ? new Date(invoice?.due_date).toISOString().split('T')[0] : '',
        invoice_total: centsToDollars(invoice?.invoice_total ?? 0),
        offline_credits_payment: centsToDollars(invoice?.offline_credits_payment ?? 0),
        outstanding_balance: centsToDollars(invoice?.outstanding_balance ?? 0),
        line_items: lineItemsFeatureFlagEnabled ? invoice.line_items : [],
        supplier_id: invoice.supplier_id ?? supplierLoginData?.supplier_id,
        external_id: invoice.external_id,
        invoice_pdf_url: invoice.invoice_pdf_url,
    });

    const publishDraftInvoiceMutation = usePublishOrRepublishDraftInvoiceMutation({
        mutationFn: publishDraftInvoice,
        successMessage: 'Invoice published successfully',
    });

    const republishInvoiceMutation = usePublishOrRepublishDraftInvoiceMutation({
        mutationFn: republishDraftInvoice,
        successMessage: 'Invoice republished successfully',
    });

    const { data: invoice, isLoading, isError: isErrorInvoice } = useGetDraftInvoice(
        {
            supplierID: supplierLoginData?.supplier_id,
            draftInvoiceID: invoiceID,
        },
        // this means that the data will be cached forever or only loaded once and never refetched
        {
            staleTime: Infinity,
            cacheTime: Infinity
        }
    );

    const canPublishDraftInvoice = invoice?.status === TransactionStatus.Draft;

    const { handleSubmit, control, reset, setValue, watch, getValues } = useForm<DraftInvoice>({
        mode: 'all',
        resolver: zodResolver(draftInvoiceValidationSchema),
    });

    const { errors, defaultValues } = useFormState({
        control,
    });

    useEffect(() => {
        if (!invoice) return;

        reset(mapInvoiceValuesToForm(invoice));

        if (invoice.customer_id && invoice?.customer?.businessName) {
            setCustomerInfo({
                name: invoice.customer?.businessName ?? '',
                address: {
                    line1: invoice.customer?.line1,
                    line2: invoice.customer?.line2,
                    city: invoice.customer?.city,
                    state: invoice.customer?.state,
                    postal_code: invoice.customer?.postalCode,
                }
            });
            setShowCustomerSearchInput(false);
        }
    }, [invoice]);

    const customerId = watch('customer_id');
    const total = watch('invoice_total');
    const paidOffline = watch('offline_credits_payment');
    // these are the line items that are being watched from the form and preloaded from the invoice
    const lineItems = watch('line_items');

    const { data: customerResults, isFetching: isFetchingCustomer } = useGetCustomer({
        customerID: customerId,
        supplierID: supplierLoginData?.supplier_id,
        gpoId,
        supplierIdFilter: invoice?.supplier_id
    });

    const { subtotal, taxAmount } = useMemo(
        () => calculateInvoiceTotalsByItems(lineItems),
        [lineItems]
    );

    useEffect(() => {
        const outstandingBalance = total - paidOffline;
        setValue('outstanding_balance', outstandingBalance);
    }, [total, paidOffline]);

    const handleLineItemsTotalCalculation = (): void => {
        const newTotal = calculateInvoiceTotalsByItems(lineItems).total;
        setValue('invoice_total', newTotal);
    };

    useEffect(() => {
        if (!lineItems) return;

        if (lineItems.length === 0 && defaultValues?.line_items?.length === 0) {
            setValue('invoice_total', centsToDollars(invoice?.invoice_total));
        } else {
            handleLineItemsTotalCalculation();
        }
    }, [lineItems, defaultValues?.line_items]);

    useEffect(() => {
        if (customerResults?.id) {
            setCustomerInfo({
                name: customerResults.name,
                address: customerResults.address
            });
        }
    }, [customerResults?.id]);

    const handleOnMutateItem = (data: DraftInvoiceItem, isNewItem: boolean): void => {
        if (isNewItem) {
            setValue('line_items', [...lineItems, data]);
        } else {
            const updatedItems = lineItems.map((item) => {
                if (item.id === data.id) {
                    return data;
                }
                return item;
            });
            setValue('line_items', updatedItems);
        }
    };

    const handleOnDeleteItem = (itemId: string) => {
        const updatedItems = lineItems.filter((lineItem) => lineItem.id !== itemId);
        setValue('line_items', updatedItems);
    };

    const handleBackButton = (): void => {
        const { history } = window;

        if (history.length > 1) {
            navigate(-1);
        } else {
            navigate(`/${ARRoutePaths.AR_INVOICES}`);
        }
    };

    const handleEditCustomerClick = (): void => {
        setShowCustomerSearchInput(true);
    };

    const handleCustomerSelect = (field, selectedCustomer: CustomerOption): void => {
        field.onChange(selectedCustomer.id);
        setCustomerInfo({
            name: selectedCustomer.name,
            address: selectedCustomer.address
        });
        setValue('customer_id', selectedCustomer.id);
        setShowCustomerSearchInput(false);
    };

    const showCustomerSearchInputInitially = !customerId && !isFetchingCustomer;
    const hasItems = invoice && lineItems?.length > 0;

    const handleSaveDraftInvoice = () => {
        // Save draft invoice without validation
        const data: DraftInvoiceFormData = getValues();
        const updatedFields = filterUpdatedFields(defaultValues as DraftInvoiceFormData, data);
        const payload = mapUpdatedFieldsToPatchBody(updatedFields);

        // temporary keeping for debug purposes
        // eslint-disable-next-line no-console
        console.log('Handle save', { data, defaultValues, updatedFields, payload });

        saveInvoiceMutation.mutate({
            draftInvoiceID: invoiceID,
            supplierID: supplierLoginData?.supplier_id,
            body: payload,
        });
    };

    const handlePublishDraftInvoice = (data:DraftInvoice) => {
        if (!canPublishDraftInvoice) {
            return;
        }

        if (!data) {
            toast.show({
                message: `Please fix the errors in the form before publishing. ${Object.keys(errors).join(', ')}`,
            });
            return;
        }
        if (invoice.transaction_id) {
            republishInvoiceMutation.mutate({
                draftInvoiceID: invoiceID,
                supplierID: supplierLoginData?.supplier_id,
            });
        } else {
            publishDraftInvoiceMutation.mutate({
                draftInvoiceID: invoiceID,
                supplierID: supplierLoginData?.supplier_id,
            });
        }
    };

    // Save draft invoice on Ctrl + S
    useKeyCombination(['s'], handleSaveDraftInvoice);
    useKeyCombination(['p'], handleSubmit(handlePublishDraftInvoice));

    if (isLoading) {
        return <Loading isDark />;
    }

    if (isErrorInvoice) {
        return (
            <>
                <section className="flex gap-2 justify-between items-center  mb-6 ">
                    <Button className="flex items-center -ml-3.5 text-gray-600"
                        as="div"
                        size="SMALL"
                        variant="SUBDUED_ICON"
                        onClick={handleBackButton}>
                        <BackIcon className="w-4 h-4 mr-3"/>
                        Back
                    </Button>
                </section>
                <div className="flex flex-col gap-4 ">
                    <PageTitle>Invoice not found</PageTitle>
                    <Typography as="div" className="text-gray-600">Apologies, no invoice matching the given criteria could be found in the system.</Typography>
                </div>
            </>
        );
    }

    return (
        <>
            <section className="flex gap-2 justify-between items-center  mb-6 ">
                <Button className="flex items-center -ml-3.5 text-gray-600"
                    size="SMALL"
                    variant="SUBDUED_ICON"
                    onClick={handleBackButton}>
                    <BackIcon className="w-4 h-4 mr-3"/>
                    Back
                </Button>
                <div className="flex gap-3 items-center shrink-0">
                    <Button size="SMALL"
                        loading={saveInvoiceMutation.isLoading}
                        onClick={handleSaveDraftInvoice}>
                                        Save
                    </Button>
                    {canPublishDraftInvoice && <Button size="SMALL"
                        variant="TERTIARY_DARK"
                        loading={publishDraftInvoiceMutation.isLoading || republishInvoiceMutation.isLoading}
                        disabled={publishDraftInvoiceMutation.isLoading || republishInvoiceMutation.isLoading || saveInvoiceMutation.isLoading}
                        onClick={handleSubmit(handlePublishDraftInvoice)}>
                        {invoice.transaction_id ? 'Republish' : 'Publish'}
                    </Button>}
                </div>

            </section>
            <form onSubmit={(e) => e.preventDefault()}>
                {/* <section className="pb-8 flex justify-between"> */}
                <section className="pb-8 flex justify-between">
                    <div>
                        <div className="flex gap-3 items-center">
                            <PageTitle>{formatAsCurrency(centsToDollars(invoice?.invoice_total))}</PageTitle>
                            <InvoiceStatusBadge status={invoice.status as TransactionStatus}/>
                        </div>
                        <Typography className="text-gray-600 mt-1" as="div"><div className="flex gap-2 item-center">
                            <span>Invoice #{invoice.invoice_number}</span>
                            <CopyButton buttonClassName="p-0"
                                textToCopy={invoice.invoice_number}
                                feedbackType={CopyButtonFeedback.TOAST}
                                successMessage="Invoice number copied"
                                errorMessage="Error copying invoice number to clipboard" />
                        </div>
                        </Typography>
                    </div>

                </section>
                <SeparatorInternalPageFullWidth className="pb-px"/>
                <section className="flex lg:flex-row flex-col-reverse">
                    <div className="-ml-10 py-10 pl-8 lg:w-5/12 w-full">

                        <div className="flex flex-col gap-8">
                            {!!invoice.invoice_pdf_url && (
                                <PanelBlock title={'Invoice PDF'}
                                    value={
                                        <iframe
                                            src={`${invoice.invoice_pdf_url}#toolbar=1&navpanes=0&scrollbar=0&view=fitH`}
                                            width="100%"
                                            height="600px"
                                            title="Invoice PDF"
                                            style={{ border: 'none' }}/>
                                    }/>
                            )}
                        </div>
                        {errors.invoice_pdf_url && <Typography className="text-red-500">{errors.invoice_pdf_url.message}</Typography>}
                    </div>
                    <div className="overflow-x-auto  lg:border-r border-r-gray-200 w-full h-full">
                        {isLoading && <Loading isDark/>}
                        {!isLoading && invoice && (
                            <section className="p-10 flex gap-6 w-full">
                                <div className="flex flex-col gap-4 customer-details w-3/5">
                                    <div className="flex flex-col gap-2 items-start w-full">
                                        <Typography className="m-0 h-[23px]">Customer</Typography>
                                        <div className="flex justify-between items-center w-full">
                                            {!customerInfo.name && isFetchingCustomer && <Loading isDark/>}
                                            {(showCustomerSearchInputInitially || showCustomerSearchInput) && (
                                                <Controller
                                                    name="customer_id"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <CustomerSearch autoFocus={showCustomerSearchInput} onSelect={(selectedCustomer) => handleCustomerSelect(field, selectedCustomer)} />
                                                    )}/>
                                            )}
                                            {(customerInfo.name && !showCustomerSearchInput) && <>
                                                <Link className="text-teal-500" to={`/${ARRoutePaths.AR_CUSTOMERS}/${customerId}`}>{customerInfo.name}</Link>
                                                <Button variant="ICON" size="ICON_SMALL" onClick={handleEditCustomerClick}>
                                                    <SearchIcon className="w-4 h-4 text-gray-600" />
                                                </Button>
                                            </>}
                                        </div>
                                        {(!isFetchingCustomer || customerInfo.name) && <div>
                                            <Typography className="mt-1">Address</Typography>
                                            <CustomerAddress address={customerInfo.address}/>
                                        </div>}
                                        {errors.customer_id && <Typography className="text-red-500">{errors.customer_id.message}</Typography>}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 w-2/5">
                                    <Controller
                                        name="invoice_number"
                                        control={control}
                                        render={({ field }) => <Input
                                            label="Invoice Number"
                                            variant="SMALL"
                                            isInvalid={!!errors.invoice_number}
                                            invalidMessage={errors.invoice_number?.message}
                                            required
                                            inputProps={{
                                                ...field,
                                            }}/>}/>
                                    <div className="flex gap-4 items-center ">
                                        <div className="relative w-full">
                                            <Controller
                                                name="invoice_date"
                                                control={control}
                                                render={({ field }) => <DatePickerInput
                                                    inputFieldProps={{
                                                        isInvalid: !!errors.invoice_date,
                                                        invalidMessage: errors.invoice_date?.message
                                                    }}
                                                    variant="SMALL"
                                                    required
                                                    label="Invoice Date"
                                                    inputProps={{ readOnly: true }}
                                                    value={field.value}
                                                    onChange={(value): void => {
                                                        const utcDate = new Date(value).toISOString().split('T')[0];
                                                        return field.onChange(utcDate);
                                                    }}
                                                    id="invoice_date"/>}/>
                                        </div>
                                        <div className="relative w-full">
                                            <Controller
                                                name="due_date"
                                                control={control}
                                                render={({ field }) => <DatePickerInput
                                                    variant="SMALL"
                                                    required
                                                    label="Due Date"
                                                    inputProps={{ readOnly: true }}
                                                    inputFieldProps={{
                                                        isInvalid: !!errors.due_date,
                                                        invalidMessage: errors.due_date?.message
                                                    }}
                                                    value={field.value}
                                                    onChange={(value): void => {
                                                        const utcDate = new Date(value).toISOString().split('T')[0];
                                                        return field.onChange(utcDate);
                                                    }}
                                                    id="due_date"/>}/>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                        {lineItemsFeatureFlagEnabled && <>
                            <section className="p-10">
                                <SectionTitle className="flex items-center gap-2 ">Line items</SectionTitle>

                                {!hasItems && <EmptyState text="There are no items in this invoice."/>}
                                {hasItems && <NotchDataGrid className="overflow-visible"
                                    columns={getInvoiceItemColumns()}
                                    autoHeight={true}
                                    getRowHeight={() => 'auto'}
                                    rowCount={lineItems.length}
                                    sx={gridSX}
                                    sortingMode="client"
                                    onRowClick={({ row }) => openModal(DraftInvoiceModals.LineItem, row)}
                                    disableSelectionOnClick
                                    disableColumnFilter
                                    disableColumnSelector
                                    disableColumnMenu
                                    rowsPerPageOptions={[50, 100]}
                                    hideFooter={true}
                                    rows={lineItems}
                                    loading={isLoading}/>}
                                <button className="bg-transparent mt-4"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        return openModal(DraftInvoiceModals.LineItem);
                                    }}>
                                    <Typography className="flex items-center font-medium cursor-pointer gap-2" >
                                        <AddIcon className="text-teal-500 w-4 h-4" />
                                        <span className="text-teal-500">Add line items</span>
                                    </Typography>
                                </button>
                                {hasItems && (
                                    <div className="flex justify-end mt-6">
                                        <div className="flex flex-col  space-y-3 lg:w-80">
                                            <Separator/>
                                            <div className="flex items-center space-x-4 justify-between">
                                                <Typography className="m-0">Subtotal:</Typography>
                                                <Typography className="m-0 text-right">{formatAsCurrency(subtotal)}</Typography>
                                            </div>
                                            <Separator/>
                                            <div className="flex items-center space-x-4 justify-between">
                                                <Typography className="m-0">Tax Amount:</Typography>
                                                <Typography className="m-0 text-right">{formatAsCurrency(taxAmount)}</Typography>
                                            </div>
                                            <Separator/>
                                        </div>

                                    </div>
                                )}
                            </section>
                            <Separator/>
                        </>}
                        <section>
                            <div className="p-10 grid customer-details">
                                <div className="flex flex-col gap-4">
                                    <div className="flex items-start gap-2">
                                        <Controller
                                            name="invoice_total"
                                            control={control}
                                            render={({ field }) => <CurrencyInput
                                                required
                                                allowNegative
                                                isInvalid={!!errors.invoice_total}
                                                invalidMessage={errors.invoice_total?.message}
                                                variant="SMALL"
                                                disabled={hasItems}
                                                onChange={(e):void => {
                                                    field.onChange(e.floatValue);
                                                }}
                                                value={field.value}
                                                label={'Invoice total'}/>}/>
                                    </div>
                                    <Controller
                                        name="offline_credits_payment"
                                        control={control}
                                        render={({ field }) => <CurrencyInput
                                            required
                                            isInvalid={!!errors.offline_credits_payment}
                                            invalidMessage={errors.offline_credits_payment?.message}
                                            variant="SMALL"
                                            allowNegative
                                            onChange={(e):void => {
                                                field.onChange(e.floatValue);
                                            }}
                                            value={field.value}
                                            label={'Offline payments & credits'}/>}/>
                                    {/* TODO: this will be a new field coming from the DB and stored  */}
                                    <Controller
                                        name="outstanding_balance"
                                        control={control}
                                        render={({ field }) => <CurrencyInput
                                            required
                                            disabled={!hasItems}
                                            isInvalid={!!errors.outstanding_balance}
                                            invalidMessage={errors.outstanding_balance?.message}
                                            variant="SMALL"
                                            allowNegative
                                            onChange={(e):void => {
                                                field.onChange(e.floatValue);
                                            }}
                                            value={field.value}
                                            label={'Outstanding balance'}/>}/>

                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </form>
            <DraftInvoiceItemFormModal onItemSubmit={handleOnMutateItem} />
            <DraftInvoiceItemDeleteModal onConfirmDelete={handleOnDeleteItem} />
        </>
    );
};
