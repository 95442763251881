import { Badge, GridColDef, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import { DraftInvoice, TransactionStatus } from '@ar/network/AccountsReceivable.network';
import WarningIcon from '@icons/warning-icon.svg';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';
import { InvoiceStatusBadge } from '@/ar/components/Invoices/InvoiceStatusBadge';

export const headerClassName = 'text-gray-600 font-body text-med';
export const cellClassName = 'text-gray-700 font-body text-med';

interface InvoiceRow extends DraftInvoice {
    isHovered: boolean,
}

export const commonGridDef: GridColDef = {
    field: '',
    headerAlign: 'left',
    headerClassName,
    cellClassName,
    align: 'left',
};

const gridColDef: GridColDef = {
    ...commonGridDef,
    sortable: false,
};

export const DRAFT_INVOICE_COLUMNS: Record<string, keyof DraftInvoice | string> = {
    invoiceTotal: 'invoice_total',
    outstandingBalance: 'outstanding_balance',
    invoiceDate: 'invoice_date',
    dueDate: 'due_date',
    invoiceNumber: 'invoice_number',
    customer: 'customer',
    status: 'status'

};

export const MissingInvoiceColumField:React.FC<{ text?: string }> = ({ text = 'Missing' }) => <Badge size="SMALL" variant="ORANGE" className="py-0.5">
    <div className="flex items-center gap-3">
        <WarningIcon className="w- h-4 shrink-0" />
        <Typography variant="BASE" weight="font-medium" className=" m-0">{text}</Typography>
    </div>
</Badge>;

export const getColumns = (): GridColDef<InvoiceRow>[] => [
    {
        ...gridColDef,
        field: DRAFT_INVOICE_COLUMNS.invoiceNumber,
        headerName: 'Invoice #',
        flex: 1,
        sortable: true,
        minWidth: 140,
        cellClassName: 'overflow-visible', // This must be set for the tooltip to be visible
        renderCell: ({ row }) => (row.invoice_number ? <Typography className="truncate">{row.invoice_number} </Typography> : <MissingInvoiceColumField />)
    },
    {
        ...gridColDef,
        field: DRAFT_INVOICE_COLUMNS.status,
        headerName: 'Status',
        minWidth: 120,
        flex: 1,
        sortable: true,
        renderCell: ({ row }) => <InvoiceStatusBadge status={row.status as TransactionStatus}/>
    },
    {
        ...gridColDef,
        field: DRAFT_INVOICE_COLUMNS.invoiceTotal,
        headerName: 'Total Amount',
        minWidth: 300,
        cellClassName: 'overflow-visible', // This must be set for the popover to be visible
        flex: 1,
        sortable: true,
        renderCell: ({ value }) => (value ? <Typography className="truncate">{formatAsCurrency(centsToDollars(value))}</Typography> : <MissingInvoiceColumField />)
    },
    {
        ...gridColDef,
        field: DRAFT_INVOICE_COLUMNS.outstandingBalance,
        headerName: 'Remaining Amount',
        minWidth: 160,
        flex: 1,
        sortable: false,
        renderCell: ({ value }) => (value ? <Typography className="truncate">{formatAsCurrency(centsToDollars(value))}</Typography> : <MissingInvoiceColumField />)
    },

    {
        ...gridColDef,
        field: DRAFT_INVOICE_COLUMNS.invoiceDate,
        headerName: 'Issue date',
        minWidth: 120,
        flex: 1,
        sortable: true,

        renderCell: ({ row }) => (row.invoice_date ? <div>{formatIsoStringToUtcDate(new Date(row.invoice_date).toISOString(), 'MMM d, yyyy')}</div> : <MissingInvoiceColumField />),
    },
    {
        ...gridColDef,
        field: DRAFT_INVOICE_COLUMNS.customer,
        headerName: 'Customer',
        minWidth: 120,
        flex: 1,
        sortable: true,

        renderCell: ({ row }) => (row.customer?.businessName ? <div>{row.customer?.businessName}</div> : <MissingInvoiceColumField text="No match" />),
    },
    {
        ...gridColDef,
        field: DRAFT_INVOICE_COLUMNS.dueDate,
        headerName: 'Due date',
        minWidth: 120,
        flex: 1,
        sortable: true,

        renderCell: ({ row }) => (row.due_date ? <div>{formatIsoStringToUtcDate(new Date(row.due_date).toISOString(), 'MMM d, yyyy')}</div> : <MissingInvoiceColumField />),
    }
];
