import { DraftInvoice, getDraftInvoice, GetDraftInvoiceIDParams, getDraftInvoices,
    GetDraftInvoicesParams,
    GetDraftInvoicesResponse } from '@ar/network/AccountsReceivable.network';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

export const FETCH_DRAFT_INVOICES_QUERY_KEY = 'FETCH_TRANSACTIONS_QUERY_KEY';

export type TUseGetDraftInvoicesQuery = {
    searchParams: GetDraftInvoicesParams,
};
export const useGetDraftInvoices = ({ searchParams }: TUseGetDraftInvoicesQuery): UseQueryResult<GetDraftInvoicesResponse> => useQuery([FETCH_DRAFT_INVOICES_QUERY_KEY, searchParams], async () => getDraftInvoices(searchParams), { enabled: !!searchParams.supplier_id });

export const DRAFT_INVOICE_QUERY_KEY = 'DRAFT_INVOICE_QUERY_KEY';
export const useGetDraftInvoice = (params: GetDraftInvoiceIDParams, options?: UseQueryOptions<DraftInvoice>): UseQueryResult<DraftInvoice> => useQuery(
    [DRAFT_INVOICE_QUERY_KEY, params],
    async () => getDraftInvoice(params),
    { enabled: !!params.draftInvoiceID && (!!params.supplierID),
        ...options }
);
