import { patchDraftInvoice, PublishDraftInvoiceParams, PublishDraftInvoiceResponse } from '@ar/network/AccountsReceivable.network';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ARRoutePaths } from '@v2/constants/EPaths';
import { AxiosError } from 'axios';
import { DRAFT_INVOICE_QUERY_KEY } from '../queries/DraftInvoicesQueries.hook';
import { queryClient } from '@/containers/app/Root';
import { FETCH_TRANSACTION_QUERY_KEY } from '../queries/InvoicesQueries.hook';

export const useSaveDraftInvoiceMutation = () => useMutation(patchDraftInvoice, {
    onSuccess: async (_, { draftInvoiceID, supplierID }) => {
        queryClient.invalidateQueries([DRAFT_INVOICE_QUERY_KEY, { draftInvoiceID, supplierID }]);

        toast.show({
            message: 'Draft Invoice saved successfully',
            icon: <CheckIcon/>,
        });
    },
    onError: () => {
        toast.show({
            message: 'Failed to save draft invoice',
        });
    },
});

interface UseDraftInvoiceMutationParams {
    mutationFn: (params:PublishDraftInvoiceParams) => Promise<PublishDraftInvoiceResponse>,
    successMessage: string,
    onSuccess?: (data: PublishDraftInvoiceResponse) => void,
}

export const usePublishOrRepublishDraftInvoiceMutation = ({ mutationFn, successMessage, onSuccess }: UseDraftInvoiceMutationParams) => {
    const navigate = useNavigate();
    return useMutation(mutationFn, {
        onSuccess: async (data) => {
            await queryClient.invalidateQueries([DRAFT_INVOICE_QUERY_KEY]);
            await queryClient.invalidateQueries([FETCH_TRANSACTION_QUERY_KEY]);

            toast.show({
                message: successMessage,
                icon: <CheckIcon />,
            });

            if (onSuccess) {
                onSuccess(data);
            }
            navigate(`/${ARRoutePaths.AR_INVOICES}/${data.id}`);
        },
        onError: (error:AxiosError<{ error?: string }>) => {
            const message = error.response?.data.error || 'Failed to publish draft invoice';
            toast.show({
                message
            });
        }
    });
};
