import React from 'react';
import { GetTransactionStatus, TransactionStatus } from '@ar/network/AccountsReceivable.network';
import { Badge } from '@notch-ordering/ui-components';
import CircleIcon from '@icons/circle-icon.svg';
import HalfCircleIcon from '@icons/half-circle-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import ArrowsTopLeftIcon from '@icons/arrows-top-left-icon.svg';
import CloseIcon from '@icons/close-icon.svg';
import ForbiddenIcon from '@icons/forbidden-icon.svg';
import ClockIcon from '@icons/clock-icon.svg';
import InfoOutlineIcon from '@icons/info-outline-icon.svg';
import DraftIcon from '@icons/draft-icon.svg';

export interface InvoiceStatusBadgeProps {
    status: TransactionStatus,
}
export type BadgeProps = 'GRAY' | 'GREEN' | 'RED' | 'ORANGE' | 'TEAL' | 'PURPLE';
const getBadgeVariantByStatus = (status: TransactionStatus):BadgeProps => {
    switch (status) {
        case TransactionStatus.Pending:
        case TransactionStatus.DoNotCharge:
        case TransactionStatus.Draft:
            return 'GRAY';

        case TransactionStatus.Charged:
        case TransactionStatus.PartialCharged:
        case TransactionStatus.ChargedOffline:
        case TransactionStatus.Confirmed:
        case TransactionStatus.Published:
            return 'GREEN';
        case TransactionStatus.Refunded:
        case TransactionStatus.Failed:
        case TransactionStatus.PartialRefund:
        case TransactionStatus.RequiresAction:
        case TransactionStatus.RequiresAction3DS:
            return 'RED';
        default:
            return 'GRAY';
    }
};

export const getPaymentStatusText = (status: GetTransactionStatus) :string => {
    switch (status) {
        case TransactionStatus.Pending:
            return 'Open';
        case TransactionStatus.PartialCharged:
            return 'Partially paid';
        case TransactionStatus.Charged:
            return 'Paid';
        case TransactionStatus.Refunded:
            return 'Refunded';
        case TransactionStatus.RequiresAction:
        case TransactionStatus.RequiresAction3DS:
            return 'Action required';
        case TransactionStatus.Failed:
            return 'Failed';
        case TransactionStatus.DoNotCharge:
            return 'Do not charge';
        case TransactionStatus.Disputed:
            return 'Disputed';
        case TransactionStatus.Scheduled:
            return 'Scheduled';
        case TransactionStatus.ChargedOffline:
            return 'Paid offline';
        case TransactionStatus.PartialRefund:
            return 'Partially refunded';
        case TransactionStatus.Processing:
            return 'Processing';
        case TransactionStatus.Draft:
            return 'Draft';
        case TransactionStatus.Published:
            return 'Published';
        case TransactionStatus.Confirmed:
            return 'Confirmed';
        default:
            return status;
    }
};

export const getBadgeIcon = (status: TransactionStatus) :React.ReactNode => {
    switch (status) {
        case TransactionStatus.Pending:
            return <CircleIcon />;
        case TransactionStatus.Draft:
            return <DraftIcon/>;
        case TransactionStatus.PartialCharged:
        case TransactionStatus.PartialRefund:
            return <HalfCircleIcon />;
        case TransactionStatus.Charged:
        case TransactionStatus.ChargedOffline:
        case TransactionStatus.Confirmed:
        case TransactionStatus.Published:
            return <CheckIcon />;
        case TransactionStatus.Refunded:
            return <ArrowsTopLeftIcon />;
        case TransactionStatus.Failed:
            return <CloseIcon />;
        case TransactionStatus.RequiresAction:
        case TransactionStatus.RequiresAction3DS:
            return <InfoOutlineIcon />;
        case TransactionStatus.DoNotCharge:
        case TransactionStatus.Disputed:
            return <ForbiddenIcon />;
        case TransactionStatus.Scheduled:
            return <ClockIcon />;
        default:
            return null;
    }
};
export const InvoiceStatusBadge : React.FC<InvoiceStatusBadgeProps> = ({ status = TransactionStatus.Pending }) => {
    const badge = {
        variant: getBadgeVariantByStatus(status),
        text: getPaymentStatusText(status),
        icon: getBadgeIcon(status),
    };
    return <Badge variant={badge.variant} iconLeft={badge.icon} className="truncate font-medium">
        {badge.text}
    </Badge>;
};
