import React from 'react';
import { Button, Popover, Typography, toast } from '@notch-ordering/ui-components';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import { Integrations, SupplierData, Transaction, TransactionStatus, updateTransaction } from '@ar/network/AccountsReceivable.network';
import MailIcon from '@icons/mail-icon.svg';
import CardIcon from '@icons/card-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import CircleIcon from '@icons/circle-icon.svg';
import ReSyncIcon from '@icons/arrows-bidirectional-icon.svg';
import RefreshIcon from '@icons/refresh-icon.svg';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import ForbiddenIcon from '@icons/forbidden-icon.svg';
import ArrowsTopLeftIcon from '@icons/arrows-top-left-icon.svg';
import { useInvoiceStore } from '@ar/stores/InvoicesStore';
import { trackCurrentPageTitle, TrackingEvents } from '@ar/constants/TrackingConstants';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { useInvoicePaidEventSyncManuallyMutation, useSendInvoiceNotificationMutation } from '@ar/hooks/mutations/InvoiceMutations';
import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { getGpoId } from '@v2/utils/GPOUtils';
import { FETCH_TRANSACTIONS_QUERY_KEY, FETCH_TRANSACTION_ACTIVITY_QUERY_KEY, FETCH_TRANSACTION_QUERY_KEY } from '@/ar/hooks/queries/InvoicesQueries.hook';
import { useAmplitude, AmplitudeEventActions, AmplitudeNotificationType, InvoiceNotificationDispatchPage, } from '@/containers/app/AmplitudeContext';

export interface InvoiceActionButtonColumnProps {
    invoice: Transaction,
}

export const DO_NOT_CHARGE_ENABLED_STATUSES: TransactionStatus[] = [
    TransactionStatus.Pending,
    TransactionStatus.PartialCharged,
    TransactionStatus.Failed,
    TransactionStatus.RequiresAction,
    TransactionStatus.RequiresAction3DS
];

export const MANUAL_CHARGE_ENABLED_STATUSES: TransactionStatus[] = [
    TransactionStatus.Pending,
    TransactionStatus.PartialCharged,
    TransactionStatus.Failed,
    TransactionStatus.RequiresAction,
    TransactionStatus.RequiresAction3DS,
];

const INVOICE_STATUS_UPDATED_TEXT = 'Invoice status updated';
const INVOICE_STATUS_NOT_UPDATED_TEXT = 'Failed to update invoice status';

export const showInvoiceStatusUpdatedToast = () => {
    toast.show({
        message: INVOICE_STATUS_UPDATED_TEXT,
        icon: <CheckIcon/>,
    });
};

export const showInvoiceStatusNotUpdatedToast = () => {
    toast.show({
        message: INVOICE_STATUS_NOT_UPDATED_TEXT,
    });
};

export const invalidateQueries = (queryClient: QueryClient) => {
    queryClient.invalidateQueries([FETCH_TRANSACTIONS_QUERY_KEY]);
    queryClient.invalidateQueries([FETCH_TRANSACTION_ACTIVITY_QUERY_KEY]);
    queryClient.invalidateQueries([FETCH_TRANSACTION_QUERY_KEY]);
};

/**
 *
 * The function checks if the status is equal to `TransactionStatus.Charged`, ensuring that the transaction is in the charged state.
 * It then evaluates whether the supplier's integration name (converted to lowercase and trimmed) is not included in the restricted integrations array or whether the supplier is not a notch email user.
 *
 * @param supplier
 * @param status
 */
export const shouldShowRefundOption = (supplier: SupplierData, status: string): boolean => {
    const show = status === TransactionStatus.Charged;

    const isNotchEmail = useSupplierStore.getState().supplierLoginData?.isNotchEmail ?? false;
    const restrictedIntegrations = [Integrations.Fidelio];
    const isRestrictedIntegration = restrictedIntegrations.indexOf(supplier?.integration_name?.toLowerCase()?.trim() as Integrations) !== -1;

    return show && (!isRestrictedIntegration || isNotchEmail);
};

type DontPopoverLabelProps = {
    label?: string,
};

export const DontPopoverLabel:React.FC<DontPopoverLabelProps> = ({ label = 'Don\'t Charge' }) => <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
    <div className="w-4"><ForbiddenIcon className="w-4 h-4 mt-0.5 "/></div>
    <div className="flex flex-col truncate">
        <div>{label }</div>
    </div>
</Typography>;

export const UndoDontPopoverLabel:React.FC<DontPopoverLabelProps> = ({ label = 'Undo Don\'t Charge' }) => <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
    <div className="w-4"><CircleIcon className="w-4 h-4 mt-0.5 "/></div>
    <div className="flex flex-col truncate">
        <div>{label }</div>
    </div>
</Typography>;

type RefreshLabelProps = {
    label?: string,
};

export const RefreshLabel: React.FC<RefreshLabelProps> = ({ label = 'Refresh Invoice' }) => <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
    <div className="w-4"><RefreshIcon className="w-4 h-4 mt-0.5 "/></div>
    <div className="flex flex-col truncate">
        <div>{label}</div>
    </div>
</Typography>;

export const refundPopoverLabel = ({ label }) => <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
    <div className="w-4"><ArrowsTopLeftIcon className="w-4 h-4 mt-0.5 "/></div>
    <div className="flex flex-col truncate">
        <div>{label}</div>
    </div>
</Typography>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InvoiceActionButtonColumn: React.FC<InvoiceActionButtonColumnProps> = ({ invoice }) => {
    const { supplierLoginData, supplier } = useSupplierStore();
    const { setModal } = useInvoiceStore();
    const { trackAmplitudeEvent } = useAmplitude();
    const supplierID = supplierLoginData?.supplier_id;
    const gpoId = getGpoId(supplierLoginData);
    const queryClient = useQueryClient();

    const sendInvoiceNotificationMutation = useSendInvoiceNotificationMutation(invoice);
    const invoicePaidEventSyncManually = useInvoicePaidEventSyncManuallyMutation();

    const updateTransactionMutation = useMutation(updateTransaction, {
        onSuccess: () => {
            showInvoiceStatusUpdatedToast();
            invalidateQueries(queryClient);
        },
        onError: showInvoiceStatusNotUpdatedToast
    });

    return <div className="flex gap-1"
        onClick={(e): void => {
            e.stopPropagation();
            trackCurrentPageTitle(TrackingEvents.moreButtonClicked);
        }}>
        <div>
            <Popover
                className="w-auto"
                button=
                    {<Button
                        variant="TERTIARY_TABLE"
                        className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                        size="SMALL"
                        stopPropagation={false}>
                        <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                            <EllipsisIcon className="h-4 w-4"/>
                        </div>
                    </Button>}
                items={[
                    {
                        label: <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
                            <div className="w-4"><MailIcon className="w-4 h-4 mt-0.5 "/></div>
                            <div className="flex flex-col truncate pr-5.5">
                                <div>Send invoice</div>
                                <div className="text-gray-600 truncate">{invoice.email}</div>
                            </div>
                        </Typography>,
                        className: 'z-50',
                        onClick: (): void => {
                            sendInvoiceNotificationMutation.mutate({
                                supplierID,
                                transactionID: invoice.id,
                                gpoId,
                            }, {
                                onSuccess: () => {
                                    trackAmplitudeEvent(
                                        AmplitudeEventActions.manualInvoiceNotificationSent,
                                        {
                                            type: AmplitudeNotificationType.IndividualNotification,
                                            page: InvoiceNotificationDispatchPage.InvoicesPage,
                                            invoice: invoice.id,
                                        }
                                    );
                                }
                            });
                            sendTrackingEvent(TrackingEvents.sendInvoiceClicked);
                        },
                    },
                    {
                        label: <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
                            <div className="w-4"><ReSyncIcon className="w-4 h-4 mt-0.5 "/></div>
                            <div className="flex flex-col truncate pr-5.5">
                                <div>Sync payment</div>
                            </div>
                        </Typography>,
                        className: 'z-50',
                        hidden: invoice.sync || (invoice.status !== 'charged') || (supplier.integration_name === 'qbd') || (!supplier.integration_name),
                        onClick: (): void => {
                            invoicePaidEventSyncManually.mutate({
                                supplierID,
                                transactionID: invoice.id,
                                gpoId
                            }, {
                                onSuccess: () => {
                                    queryClient.invalidateQueries([FETCH_TRANSACTIONS_QUERY_KEY]);
                                },
                            });
                            sendTrackingEvent(TrackingEvents.invoicePaidEventSyncManuallyClicked);
                        },
                    },
                    {
                        label: <DontPopoverLabel/>,
                        className: 'z-50',
                        hidden: !DO_NOT_CHARGE_ENABLED_STATUSES.includes(invoice.status as TransactionStatus),
                        onClick: (): void => {
                            setModal('doNotChargeInvoice', true, invoice);
                            sendTrackingEvent(TrackingEvents.doNotChargeClicked);
                        },
                    },
                    {
                        label: <UndoDontPopoverLabel/>,
                        className: 'z-50',
                        hidden: invoice.status !== TransactionStatus.DoNotCharge,
                        onClick: (): void => {
                            updateTransactionMutation.mutate(
                                {
                                    supplierID,
                                    gpoId,
                                    transactionID: invoice.id,
                                    body: {
                                        do_no_charge: false,
                                    },
                                },
                            );

                            sendTrackingEvent(TrackingEvents.openInvoiceClicked);
                        },
                    },
                    {
                        label: refundPopoverLabel({ label: 'Refund customer' }),
                        className: 'z-50',
                        hidden: !shouldShowRefundOption(supplier, invoice.status),
                        onClick: (): void => {
                            setModal('refundCustomer', true, invoice);
                            sendTrackingEvent(TrackingEvents.refundClicked);
                        },
                    },

                    {
                        label: <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
                            <div className="w-4"><CardIcon className="w-4 h-4 mt-0.5 "/></div>
                            <div className="flex flex-col truncate">
                                <div>Charge customer</div>
                            </div>
                        </Typography>,
                        className: 'z-50',
                        hidden: !MANUAL_CHARGE_ENABLED_STATUSES.includes(invoice.status as TransactionStatus),
                        onClick: (): void => {
                            setModal('manualCharge', true, invoice);
                            sendTrackingEvent(TrackingEvents.chargeInvoiceClicked);
                        },
                    },
                ]}/>

        </div>
    </div>;
};
