import '../../../styles/dataPage.scss';
import React, { useState } from 'react';
import { Loading, NotchDataGrid, Typography } from '@notch-ordering/ui-components';
import { PageTitle } from '@ar/components/PageTitle';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { GridSortModel } from '@mui/x-data-grid';
import { getColumns, DRAFT_INVOICE_COLUMNS } from '@ar/pages/DraftInvoicesPage/DraftInvoicePageConstants';
import { DraftInvoiceSortBy } from '@ar/network/AccountsReceivable.network';
import { useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { ARRoutePaths } from '@v2/constants/EPaths';
import { useNavigate } from 'react-router-dom';
import { getGpoId } from '@v2/utils/GPOUtils';
import { DraftInvoiceFilters } from '@/ar/components/DraftInvoices/DraftInvoiceFilters/DraftInvoiceFilters';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { useDraftInvoicesStore } from '@/ar/stores/DraftInvoicesStore';
import { useGetDraftInvoices } from '@/ar/hooks/queries/DraftInvoicesQueries.hook';

/**
 * The AR Draft Invoices page
 *
 * @returns JSX Element
 */
export const DraftInvoicesPage = (): JSX.Element => {
    useDocumentTitle('Draft Invoices - Notch');
    const navigate = useNavigate();
    const { searchParams, updateSearchParams, haveSearchParamsChanged } = useDraftInvoicesStore();
    const { supplierLoginData } = useSupplierStore();
    const gpoId = getGpoId(supplierLoginData);
    const { data: draftInvoicesData, status, isLoading: isDraftInvoicesLoading } = useGetDraftInvoices({
        searchParams: {
            ...searchParams,
            offset: searchParams.page * searchParams.limit,
            supplier_id: supplierLoginData?.supplier_id,
            limit: searchParams.limit,
        }
    });
    const supplier = useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
        gpoId,
    });
    const isLoading = isDraftInvoicesLoading || supplier.isLoading;
    const [hoveredRowID, setHoveredRowID] = useState<string>('');
    const draftInvoices = draftInvoicesData?.invoices || [];
    const draftInvoiceRows = draftInvoices.map((invoice) => ({
        ...invoice,
        isHovered: hoveredRowID === invoice.id
    }));
    const hasResults = draftInvoices.length > 0;
    const successStatus = status === 'success';
    const showResults = successStatus && hasResults;
    const emptyFilteredResults = successStatus && !hasResults && haveSearchParamsChanged();
    const noResults = successStatus && draftInvoicesData?.total === 0 && !haveSearchParamsChanged();

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        const { field, sort } = sortModel[0] ?? {
            field: undefined,
            sort: undefined,
        };

        if (!field || !sort) {
            updateSearchParams({ sort_by: undefined });
            return;
        }

        const direction = sort === 'asc' ? '' : '-';
        const fieldToSortBy: DraftInvoiceSortBy = `${direction}${field}` as DraftInvoiceSortBy;
        updateSearchParams({
            sort_by: fieldToSortBy,
        });
    }, []);

    const getColumnsVisibilityModel = (): Record<string, boolean> => {
        // if keys are not present in the object by default, all the columns are visible.
        if (supplierLoginData.gpo) {
            return {};
        }
        const visibilityModel = {
            [DRAFT_INVOICE_COLUMNS.supplier]: false,
        };

        return visibilityModel;
    };

    return (
        <>

            <div className="flex items-start"><PageTitle>Drafts</PageTitle>

            </div>

            <div className="mt-2">
                <DraftInvoiceFilters />
                {isLoading && <Loading isDark className="mt-10" />}
                {showResults && <>
                    <NotchDataGrid
                        autoHeight
                        loading={isLoading}
                        columnVisibilityModel={getColumnsVisibilityModel()}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableSelectionOnClick
                        disableColumnFilter
                        keepNonExistentRowsSelected
                        disableColumnSelector
                        disableColumnMenu
                        columns={getColumns()}
                        page={searchParams.page}
                        rowCount={draftInvoicesData?.total ?? 0}
                        rowsPerPageOptions={[searchParams.limit]}
                        pageSize={searchParams.limit}
                        paginationMode="server"
                        rowHeight={61}
                        initialState={{
                            sorting: {
                                sortModel: [{
                                    field: searchParams.sort_by?.replace(/^-/, ''),
                                    sort: searchParams.sort_by?.startsWith('-') ? 'desc' : 'asc',
                                }],
                            }
                        }}
                        onPageChange={(newPage): void => {
                            updateSearchParams({ page: newPage });
                            const pageChangeEvent = newPage > searchParams.page ? TrackingEvents.goToNextPageClicked : TrackingEvents.goToPreviousPageClicked;
                            sendTrackingEvent(pageChangeEvent);
                        } }
                        rows={draftInvoiceRows}
                        onRowClick={({ row }) => {
                            if (!row.id) return;
                            navigate(`/${ARRoutePaths.AR_DRAFT_INVOICES}/${row.id}`);
                        } }
                        componentsProps={{
                            row: {
                                onMouseEnter: (event: React.MouseEvent<HTMLElement>) => {
                                    const rowID = event.currentTarget.getAttribute('data-id');
                                    if (rowID) {
                                        setHoveredRowID(rowID);
                                    }
                                },
                                onMouseLeave: () => setHoveredRowID(null)
                            }
                        }}
                        sx={{
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                            },
                        }} />
                </>}
                {emptyFilteredResults && <div className="flex flex-col gap-2 items-center justify-center mt-32">
                    <Typography variant="LG-2" weight="font-semibold">No results found</Typography>
                    <Typography className="text-gray-600">Try searching for something else or adjusting your filters</Typography>
                </div>}
                {noResults && <div className="flex flex-col gap-2 items-center justify-center mt-32">
                    <Typography variant="LG-2" weight="font-semibold">No results found</Typography>
                    <Typography className="text-gray-600">No invoices found for this supplier</Typography>
                </div>}
            </div>

        </>

    );
};
