import '../../../styles/dataPage.scss';
import React, { useMemo, useState } from 'react';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { Button, Loading, NotchDataGrid, Popover, Separator, Typography, toast } from '@notch-ordering/ui-components';
import { ARRoutePaths } from '@v2/constants/EPaths';
import BackIcon from '@icons/back-icon.svg';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import { DO_NOT_CHARGE_ENABLED_STATUSES,
    DontPopoverLabel,
    MANUAL_CHARGE_ENABLED_STATUSES,
    UndoDontPopoverLabel,
    invalidateQueries,
    refundPopoverLabel,
    RefreshLabel,
    shouldShowRefundOption,
    showInvoiceStatusNotUpdatedToast,
    showInvoiceStatusUpdatedToast } from '@ar/components/Invoices/InvoiceActionButtonColumn/InvoiceActionButtonColumn';
import ArrowsBidirectionalIcon from '@icons/arrows-bidirectional-icon.svg';
import ReSyncIcon from '@icons/arrows-bidirectional-icon.svg';
import { PageTitle } from '@ar/components/PageTitle';
import { FETCH_TRANSACTION_ACTIVITY_QUERY_KEY,
    FETCH_TRANSACTIONS_QUERY_KEY,
    useGetInvoiceAttachments,
    useGetTransaction,
    useGetTransactionActivity, useGetTransactionActivityByEntityIDs,
    useGetTransactionItems,
    useGetTransactionPayouts } from '@ar/hooks/queries/InvoicesQueries.hook';
import { InvoiceStatusBadge } from '@ar/components/Invoices/InvoiceStatusBadge';
import { Activity, ActivityType, EIntegrationType, GetTransactionActivityParams, InvoicePDFGenerationType, TransactionStatus, updateTransaction } from '@ar/network/AccountsReceivable.network';
import { SeparatorInternalPageFullWidth } from '@ar/components/shared/SeparatorInternalPageFullWidth';
import { SectionTitle } from '@ar/components/shared/SectionTitle';
import { EmptyState,
    getInvoiceCreditColumns,
    getInvoiceItemColumns,
    getInvoicePaymentColumns,
    gridSX,
    INVOICE_PAYMENTS_COLUMNS,
    PanelBlock } from '@ar/pages/InvoiceDetailsPage/InvoiceDetailsPageConstants';
import { useGetCustomer, useGetCustomerPaymentMethods } from '@ar/hooks/queries/CustomerQueries.hook';
import { getFlagIconByCurrency } from '@ar/pages/PayoutDetailsPage/PayoutDetailsPageConstants';
import PaymentMethodBadge from '@ar/components/shared/PaymentMethodBadge/PaymentMethodBadge';
import { useInvoicePaidEventSyncManuallyMutation,
    useSendInvoiceNotificationMutation } from '@ar/hooks/mutations/InvoiceMutations';
import { ManualChargeModal } from '@ar/components/Invoices/Modals/ManualChargeModal';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { useInvoiceStore } from '@ar/stores/InvoicesStore';
import { DoNotChargeModal } from '@ar/components/Invoices/Modals/DoNotChargeModal';
import { RefundCustomerModal } from '@ar/components/Invoices/Modals/RefundCustomerModal';
import { v4 as uuidv4 } from 'uuid';
import { InvoiceEventItem } from '@ar/components/InvoiceDetails/InvoiceEventItem';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreatePaymentMethod } from '@ar/components/shared/modals/CreatePaymentMethod/CreatePaymentMethod';
import { getGpoId } from '@v2/utils/GPOUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';
import { centsToDollars, formatAsCurrency, formatPhoneNumber } from '@/utils/Formatters';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { downloadAttachmentPDF, EConfigurationType, getQboInvoicePDF, resyncSage300Invoice } from '@/ar/network/Bushwhack.network';
import { useGetSupplierConfiguration } from '@/ar/hooks/queries/IntegrationsQuery.hook';
import { useAmplitude, AmplitudeEventActions, AmplitudeNotificationType, InvoiceNotificationDispatchPage } from '@/containers/app/AmplitudeContext';
import { useAutoPayMethod } from '@/ar/hooks/queries/AutoPayMethod.hook';
import { getPaymentLink } from '@/utils/InvoiceUtils';
import { CopyButton, CopyButtonFeedback } from '@/ar/components/shared/CopyButton';
import { FeatureFlags } from '@/constants/FeatureFlags';
import { getIntegrationTypeForRoutePath } from '../IntegrationsPage/IntegrationUtils';

export const syncWithQBOPopoverLabel = ({ label } = { label: 'Sync to Quickbooks Online' }) => <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
    <div className="w-4"><ArrowsBidirectionalIcon className="w-4 h-4 mt-0.5 "/></div>
    <div className="flex flex-col truncate">
        <div>{label}</div>
    </div>
</Typography>;

export const reSyncLabel = ({ label } = { label: 'Sync payment' }) => <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
    <div className="w-4"><ReSyncIcon className="w-4 h-4 mt-0.5 "/></div>
    <div className="flex flex-col truncate">
        <div>{label}</div>
    </div>
</Typography>;

/**
 * The AR Invoices detail page
 *
 * @returns JSX Element
 */
export const InvoiceDetailsPage = function InvoiceDetailsPage(): JSX.Element {
    useDocumentTitle('Invoice Details - Notch');
    const { trackAmplitudeEvent } = useAmplitude();
    const flags = useFlags<FeatureFlags>();
    const [isDownloading, setIsDownloading] = useState(false);
    const { supplierLoginData, supplier } = useSupplierStore();
    const gpoId = getGpoId(supplierLoginData);
    const { invoiceID } = useParams();
    const { setModal, modals } = useInvoiceStore();
    const queryClient = useQueryClient();
    const { data: supplierConfiguration } = useGetSupplierConfiguration(supplierLoginData.supplier_id);
    const integrationConfiguration = supplierConfiguration?.results?.find((integration) => [EConfigurationType.ArRutter, EConfigurationType.QBO].includes(integration.type) && integration.ownerID === supplierLoginData?.supplier_id);
    const updateTransactionMutation = useMutation(updateTransaction, {
        onSuccess: () => {
            showInvoiceStatusUpdatedToast();
            invalidateQueries(queryClient);
        },
        onError: showInvoiceStatusNotUpdatedToast
    });

    const integrationType = getIntegrationTypeForRoutePath(integrationConfiguration?.type);
    const isSage300IntegationType = supplier?.integration_name === EIntegrationType.Sage300;

    const { data: invoice, isLoading, isError: isErrorInvoice } = useGetTransaction({
        supplier_id: supplierLoginData?.supplier_id,
        transactionID: invoiceID,
        gpo_id: gpoId
    });

    const invoiceAttachmentsResponse = useGetInvoiceAttachments(invoice?.external_id, integrationConfiguration?.id, integrationType);
    const invoiceAttachments = invoiceAttachmentsResponse.data?.attachments;
    const invoicePdfLink = invoiceAttachmentsResponse.data?.invoice_pdf_link;
    const hasQboPdf = integrationConfiguration?.type === EConfigurationType.QBO;
    const hasInvoiceAttachments = invoiceAttachments?.length > 0 || !!invoicePdfLink || hasQboPdf;

    const { data: invoiceItemsResponse, isLoading: isLoadingInvoiceItems } = useGetTransactionItems({
        transactionID: invoice?.id,
        supplier_id: supplierLoginData?.supplier_id,
        gpo_id: gpoId,
    });

    const { data: customerResults, isLoading: isLoadingCustomer } = useGetCustomer({ customerID: invoice?.customer_id,
        supplierID: supplierLoginData?.supplier_id,
        gpoId,
        supplierIdFilter: invoice?.supplier_id });

    const { data: paymentMethods } = useGetCustomerPaymentMethods({ customerID: invoice?.customer_id, supplierID: invoice?.supplier_id, gpoId });

    const getActivityParams: Partial<GetTransactionActivityParams> = {
        supplier_id: supplierLoginData?.supplier_id,
        gpo_id: gpoId,
    };

    const { data: invoiceActivityResponse, isLoading: isLoadingActivity } = useGetTransactionActivity({
        ...getActivityParams,
        entity_type: 'invoice',
        entity_id: invoice?.id
    });
    const invoiceActivities = invoiceActivityResponse?.activities ?? [];

    const refundStripeIDs = invoice?.refund_details?.map((refund) => refund?.stripe_id).filter((id) => !!id) ?? [];
    const refundActivityResults = useGetTransactionActivityByEntityIDs(refundStripeIDs, {
        ...getActivityParams,
        entity_type: 'refund',
    });

    const getGeneratePDFOnDemandActivityItem = (): Activity | null => {
        const hasPdfAttachedActivity = invoiceActivityResponse?.activities.some((activity) => activity.type === ActivityType.InvoicePdfAttached);
        // If the supplier has invoice_pdf_generation set to OnDemand and the invoice does not have a pdf attached and there is no activity for pdf attached
        // return an activity item to generate the pdf
        if (supplier?.invoice_pdf_generation === InvoicePDFGenerationType.OnDemand && !invoice?.invoice_pdf_url && !hasPdfAttachedActivity) {
            return {
                created_at: new Date().toISOString(),
                description: '',
                id: uuidv4(),
                type: ActivityType.InvoicePdfGenerationRequested,
                entity_id: invoice?.id,
                payload: {
                    invoice_pdf: '',
                },
            };
        }
        return null;
    };

    const refundActivities = useMemo(() => refundActivityResults?.map((r) => r?.data?.activities).filter((r) => r?.length > 0)?.flat() ?? [], [refundActivityResults]);
    const events = useMemo(() => {
        // Get the generatePDFOnDemandActivityItem if it exists based on the conditions in the function
        const generatePDFOnDemandActivityItem = getGeneratePDFOnDemandActivityItem();
        // Flatten the refundActivities array and merge it with invoiceActivityResponse?.activities
        const allActivities: Activity[] = [
            ...(invoiceActivityResponse?.activities ?? []), ...refundActivities];
        if (generatePDFOnDemandActivityItem) {
            allActivities.push(generatePDFOnDemandActivityItem);
        }
        // Sort the merged array by date in descending order
        allActivities.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        return allActivities ?? [];
    }, [invoiceActivities, refundActivities]);

    const { data: invoicePayoutsResponse, isLoading: isLoadingPayouts } = useGetTransactionPayouts({
        supplier_id: supplierLoginData?.supplier_id,
        gpo_id: gpoId,
        transactionID: invoice?.id,
    });

    const payouts = invoicePayoutsResponse?.payouts ?? [];
    const hasPayouts = !isLoadingPayouts && payouts.length > 0;

    const hasEvents = !isLoadingActivity && events.length > 0;

    const invoiceItems = invoiceItemsResponse?.items ?? [];
    const credits = invoice?.credits_applied ?? [];
    const hasCredits = !isLoading && credits.length > 0;
    const items = invoiceItems.filter((item) => item.type === 'invoice') ?? [];
    const hasItems = !isLoadingInvoiceItems && items.length > 0;
    const sendNotificationMutation = useSendInvoiceNotificationMutation(invoice);
    const invoicePaidEventSyncManually = useInvoicePaidEventSyncManuallyMutation();
    const navigate = useNavigate();
    const payments = invoice?.charges ?? [];
    const hasPayments = !isLoading && payments.length > 0;

    const [isInvoiceSent, setIsInvoiceSent] = useState(false);

    const [autoPayMethod] = useAutoPayMethod(invoice?.supplier_id, supplierLoginData?.isCustomer, paymentMethods);

    const handleSendInvoice = async () => {
        setIsInvoiceSent(true);
        sendNotificationMutation.mutate({ supplierID: supplierLoginData?.supplier_id, transactionID: invoice?.id, gpoId }, {
            onSuccess: () => {
                queryClient.invalidateQueries([FETCH_TRANSACTION_ACTIVITY_QUERY_KEY]);

                trackAmplitudeEvent(
                    AmplitudeEventActions.manualInvoiceNotificationSent,
                    {
                        type: AmplitudeNotificationType.IndividualNotification,
                        page: InvoiceNotificationDispatchPage.InvoiceDetailsPage,
                        invoice: invoice?.id,
                    }
                );
            },
            onError: () => {
                setIsInvoiceSent(false);
            }
        });
    };

    const resyncSage300InvoiceMutation = useMutation(resyncSage300Invoice, {
        onSuccess: () => {
            invalidateQueries(queryClient);

            toast.show({
                message: 'Invoice has been refreshed',
                icon: <CheckIcon/>,
            });
        },
        onError: () => {
            toast.show({
                message: 'Error while attempting to re-sync invoice from Sage 300',
            });
        }
    });

    const handleSage300InvoiceRefresh = () => {
        resyncSage300InvoiceMutation.mutate({
            transactionID: invoice.id,
            supplierID: supplierLoginData?.supplier_id,
        });

        sendTrackingEvent(TrackingEvents.refreshInvoiceClicked);
    };

    const popoverActionItems = useMemo(() => [
        {
            label: <RefreshLabel/>,
            hidden: !isSage300IntegationType, // Available only for Sage 300 integration
            onClick: handleSage300InvoiceRefresh,
        },
        {
            label: <DontPopoverLabel/>,
            hidden: !DO_NOT_CHARGE_ENABLED_STATUSES.includes(invoice?.status as TransactionStatus),
            onClick: (): void => {
                setModal('doNotChargeInvoice', true, invoice);
                sendTrackingEvent(TrackingEvents.doNotChargeClicked);
            },
        }, {
            label: <UndoDontPopoverLabel/>,
            hidden: invoice?.status as TransactionStatus !== TransactionStatus.DoNotCharge,
            onClick: (): void => {
                updateTransactionMutation.mutate(
                    {
                        supplierID: supplierLoginData?.supplier_id,
                        gpoId,
                        transactionID: invoice.id,
                        body: {
                            do_no_charge: false,
                        },
                    },
                );

                sendTrackingEvent(TrackingEvents.openInvoiceClicked);
            },
        }, {
            label: refundPopoverLabel({ label: 'Refund' }),
            hidden: !shouldShowRefundOption(supplier, invoice?.status),
            onClick: (): void => {
                setModal('refundCustomer', true, invoice);
                sendTrackingEvent(TrackingEvents.refundClicked);
            },
        }, {
            label: syncWithQBOPopoverLabel(),
            hidden: true,
            onClick: ():void => {
            },
        }, {
            label: reSyncLabel(),
            hidden: invoice?.sync || (invoice?.status !== 'charged') || (supplier.integration_name === 'qbd') || (!supplier.integration_name),
            onClick: (): void => {
                invoicePaidEventSyncManually.mutate({
                    supplierID: supplierLoginData?.supplier_id,
                    transactionID: invoice.id,
                    gpoId,
                }, {
                    onSuccess: () => {
                        queryClient.invalidateQueries([FETCH_TRANSACTIONS_QUERY_KEY]);
                    },
                });
                sendTrackingEvent(TrackingEvents.invoicePaidEventSyncManuallyClicked);
            },
        },
    ], [invoice?.status, supplier]);

    const shouldShowActionButton = useMemo(() => popoverActionItems.some((item) => !item.hidden), [popoverActionItems]);

    const { isFetching: isSupplierLoading } = useGetSupplier({
        supplierID: invoice?.supplier_id || supplierLoginData?.supplier_id,
        gpoId
    });

    if (isLoading) {
        return <Loading isDark />;
    }
    const getColumnsVisibilityModel = (): Record<string, boolean> => {
        // if keys are not present in the object by default, all the columns are visible.

        const visibilityModel = {
            [INVOICE_PAYMENTS_COLUMNS.surchargeAmount]: flags.paymentSurcharges || invoice.is_surcharge_enabled || !!invoice.surcharge_amount
        };

        return visibilityModel;
    };
    const handleBackButton = (): void => {
        const { history } = window;
        if (history.length > 1) {
            history.back();
        } else {
            navigate(`/${ARRoutePaths.AR_INVOICES}`);
        }
    };
    if (isErrorInvoice) {
        return <>
            <section className="flex gap-2 justify-between items-center  mb-6 ">
                <Button className="flex items-center -ml-3.5 text-gray-600"
                    size="SMALL"
                    variant="SUBDUED_ICON"
                    onClick={handleBackButton}>
                    <BackIcon className="w-4 h-4 mr-3"/>
                    Back
                </Button>
            </section>
            <div className="flex flex-col gap-4 ">
                <PageTitle>Invoice not found</PageTitle>
                <Typography as="div" className="text-gray-600">Apologies, no invoice matching the given criteria could be found in the system.</Typography>
            </div>
        </>;
    }

    const paymentLink = getPaymentLink(flags?.supplierPublicInvoiceLink, supplier.id, invoice.invoice_number, invoice.payment_link_url);

    return (
        <>
            <section className="flex gap-2 justify-between items-center  mb-6 ">
                <Button className="flex items-center -ml-3.5 text-gray-600"
                    size="SMALL"
                    variant="SUBDUED_ICON"
                    onClick={handleBackButton}>
                    <BackIcon className="w-4 h-4 mr-3"/>
                    Back
                </Button>
                <div className="flex gap-3 items-center shrink-0">
                    <Button size="SMALL"
                        loading={sendNotificationMutation.isLoading}
                        disabled={isInvoiceSent}
                        onClick={handleSendInvoice}>
                        {isInvoiceSent ? <div className="flex items-center gap-1"><CheckIcon className="w-4 h-4" /><span>Invoice has been sent </span></div> : 'Send Invoice'}
                    </Button>
                    {MANUAL_CHARGE_ENABLED_STATUSES.includes(invoice.status as TransactionStatus)
                    && <Button size="SMALL"
                        onClick={(): void => {
                            setModal('manualCharge', true, invoice);
                            sendTrackingEvent(TrackingEvents.chargeInvoiceClicked);
                        }}>
                        Charge
                    </Button>}
                    {shouldShowActionButton && <Popover
                        className="w-60"
                        button={
                            <Button
                                size="ICON_SMALL"
                                className="min-w-min"
                                stopPropagation={false}>
                                <div>
                                    <EllipsisIcon className="h-4 w-4"/>
                                </div>
                            </Button>
                        }
                        items={popoverActionItems}/>}

                </div>
            </section>
            <section className="pb-8">
                <div className="flex gap-3 items-center">
                    <PageTitle>{formatAsCurrency(centsToDollars(invoice?.total))}</PageTitle>
                    <InvoiceStatusBadge status={invoice.status as TransactionStatus}/>
                </div>
                <Typography className="text-gray-600 mt-1" as="div">Invoice #{invoice.invoice_number}</Typography>
            </section>
            <SeparatorInternalPageFullWidth className="pb-px"/>
            <section className="flex lg:flex-row flex-col-reverse">
                <div className="overflow-x-auto -ml-10 lg:border-r border-r-gray-200 w-full h-full">
                    <section className="p-10">
                        <SectionTitle className="mb-6">Items</SectionTitle>
                        {isLoadingInvoiceItems && <Loading isDark/>}
                        {!hasItems && <EmptyState text="There are no items in this invoice."/>}
                        {hasItems && <NotchDataGrid columns={getInvoiceItemColumns()}
                            autoHeight={true}
                            getRowHeight={() => 'auto'}
                            rowCount={items.length}
                            sx={gridSX}
                            sortingMode="client"
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            disableColumnMenu
                            rowsPerPageOptions={[50, 100]}
                            hideFooter={true}
                            rows={items}
                            loading={isLoadingInvoiceItems}/>}
                    </section>
                    <Separator/>
                    <section className="p-10">
                        <SectionTitle className="mb-6">Credits</SectionTitle>
                        {isLoading && <Loading isDark/>}
                        {!hasCredits && <EmptyState text="No credits have been applied to this invoice yet"/>}
                        {hasCredits && <NotchDataGrid columns={getInvoiceCreditColumns()}
                            autoHeight
                            getRowHeight={() => 'auto'}
                            rowsPerPageOptions={[50, 100]}
                            sx={gridSX}
                            getRowId={(row) => row.external_id ?? uuidv4()}
                            rowCount={credits.length}
                            sortingMode="client"
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            disableColumnMenu
                            hideFooter={true}
                            rows={credits}
                            loading={isLoading}
                            components={{
                                NoRowsOverlay: () => <Typography className="mt-4 w-full">This invoice has no credit applied
                                </Typography>,
                            }}/>}

                    </section>

                    <Separator/>
                    <section className="p-10">
                        <SectionTitle className="mb-6">Payments</SectionTitle>
                        {isLoading && <Loading isDark/>}
                        {!hasPayments && <EmptyState text="No payments have been made towards this invoice yet"/>}
                        {hasPayments && <NotchDataGrid columns={getInvoicePaymentColumns()}
                            autoHeight
                            getRowHeight={() => 'auto'}
                            columnVisibilityModel={getColumnsVisibilityModel()}
                            rowsPerPageOptions={[50, 100]}
                            sx={gridSX}
                            getRowId={(row) => row.external_id ?? uuidv4()}
                            rowCount={payments.length}
                            sortingMode="client"
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            disableColumnMenu
                            hideFooter={true}
                            rows={payments}
                            components={{
                                NoRowsOverlay: () => <Typography className="mt-4 w-full">This invoice has no payment</Typography>,
                            }}
                            loading={isLoading}/>}
                    </section>
                    <Separator/>
                    { hasInvoiceAttachments
                    && <section className="p-10">
                        <SectionTitle className="mb-6">Attachments</SectionTitle>
                        {isLoading && <Loading isDark/>}
                        {/* Rutter PDF links if they are available */}
                        {invoicePdfLink && <div className="flex flex-col">
                            <div className="flex flex-col justify-start items-start">
                                <div className="flex flex-row">
                                    <a href={invoicePdfLink}
                                        className="text-teal-300 hover:text-teal-400 active:text-teal-500 py-1 px-1"
                                        target="_blank"
                                        rel="noreferrer">
                                        {invoice.invoice_number}.pdf
                                    </a>
                                </div>
                            </div>
                        </div>}
                        {/* QBO native PDF download */}
                        {hasQboPdf && <div hidden={isDownloading} className="flex flex-col">
                            <div className="flex flex-col justify-start items-start">
                                <div className="flex flex-row">
                                    <Button variant="LINK"
                                        size="NO_BACKGROUND"
                                        className="px-1"
                                        stopPropagation={false}
                                        onClick={(): void => {
                                            setIsDownloading(true);
                                            getQboInvoicePDF(integrationConfiguration.id, invoice.external_id, invoice.invoice_number)
                                                .finally(() => setIsDownloading(false));
                                        }}>
                                        {invoice.invoice_number}.pdf
                                    </Button>
                                </div>
                            </div>
                        </div>}
                        {/* Rutter/QBO native invoice attachments manually uploaded by the user */}
                        {invoiceAttachments?.map((attachment) => <><div hidden={!isDownloading} className=" h-48 flex flex-col justify-center">
                            <Loading isDark/>
                        </div>
                        <div hidden={isDownloading} className="flex flex-col">
                            <div className="flex flex-col justify-start items-start">
                                <div className="flex flex-row">
                                    <Button variant="LINK"
                                        size="NO_BACKGROUND"
                                        className="px-1"
                                        stopPropagation={false}
                                        onClick={(): void => {
                                            setIsDownloading(true);
                                            downloadAttachmentPDF(invoice.external_id, attachment, integrationConfiguration.id, integrationConfiguration.type)
                                                .finally(() => setIsDownloading(false));
                                        }}>
                                        {`${attachment.file_name ?? 'download pdf'}`}
                                    </Button>
                                </div>
                            </div>
                        </div><br/></>)}

                    </section>
                    }
                    { hasInvoiceAttachments
                    && <Separator/>
                    }
                    <section className="p-10">
                        <SectionTitle className="mb-12">Events</SectionTitle>
                        {isLoadingActivity && <Loading isDark/>}
                        {!hasEvents && <EmptyState text="No events have been recorded for this invoice yet."/>}
                        {hasEvents && <div className="flex flex-col gap-5">
                            <>
                                {events.map((event, index) => <InvoiceEventItem key={event.id} event={event} isLast={index === events.length - 1} isFirst={index === 0}/>)}
                                {events.length === 0 && <Typography className="text-gray-600">No events</Typography>}
                            </>

                        </div>}

                    </section>
                </div>
                <div className="py-10 pl-8 max-w-[272px] w-full" >
                    {isLoadingCustomer && <Loading isDark/> }
                    {!isLoadingCustomer && <div className="flex flex-col gap-8">
                        <PanelBlock title={'Customer Name'} value={<Link className="text-teal-500" to={`/${ARRoutePaths.AR_CUSTOMERS}/${customerResults.id}`}>{customerResults.name}</Link>}/>
                        <PanelBlock title={'Contact information'}
                            value={<div className="flex gap-1 flex-col truncate">
                                <div>{customerResults.email}</div>
                                {!!customerResults.phone && <div>{formatPhoneNumber(customerResults.phone)}</div>}
                            </div>}/>
                        <PanelBlock
                            title={'Issue date'}
                            value={invoice.invoice_date ? <div>{formatIsoStringToUtcDate(new Date(invoice.invoice_date).toISOString(), 'MMM d, yyyy')}</div> : '--'}/>
                        <PanelBlock
                            title={'Due date'}
                            value={invoice.due_date ? <div>{formatIsoStringToUtcDate(new Date(invoice.due_date).toISOString(), 'MMM d, yyyy')}</div> : '--'}/>
                        <PanelBlock title={'Payment terms'} value={customerResults.terms > 1 ? `${customerResults.terms} days` : `${customerResults.terms} day` }/>
                        <PanelBlock title={'Currency'}
                            value={<div className="flex gap-2 items-center">{isSupplierLoading ? <Loading isDark/> : <>
                                <span>{getFlagIconByCurrency(supplier?.currency)}</span>
                                <span>{supplier?.currency?.toUpperCase()}</span>
                            </>}</div>}/>
                        <PanelBlock title={'Payment Link'}
                            value={<div className="flex flex-row items-center">
                                <div className="truncate text-teal-500">{paymentLink}</div>
                                <CopyButton textToCopy={paymentLink} feedbackType={CopyButtonFeedback.TOAST} successMessage="Invoice link copied" errorMessage="Error copying payment link to clipboard" />
                            </div>} />
                        {invoice.auto_collection && customerResults.has_payment_method && autoPayMethod
                        && <PanelBlock title="AutoPay" value={<PaymentMethodBadge brand={autoPayMethod.brand} last4={autoPayMethod.last4} autoCollection={false} />}/>
                        }
                        {hasPayouts && <PanelBlock title={'Payout reference'}
                            value={<div className="space-y-1">
                                {payouts?.map((pt) => <Link key={pt.id} className="block text-teal-500" to={`/${ARRoutePaths.AR_PAYOUTS}/${pt.id}`}>
                                    {pt.id.replace('po_', '')}
                                </Link>)}
                            </div>}/>}

                    </div>
                    }
                </div>
            </section>

            <ManualChargeModal/>
            <DoNotChargeModal/>
            <RefundCustomerModal/>
            <CreatePaymentMethod
                isOpen={modals?.addPaymentMethod}
                handleClose={(): void => {
                    setModal('addPaymentMethod', false, invoice);
                }}
                customerID={invoice?.customer_id}
                subTitle={invoice?.business_name}/>
        </>
    );
};
