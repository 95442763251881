import debounce from 'lodash/debounce';
import React, { useMemo, useState } from 'react';

import { InvoiceRangeDateFilterButton } from '@ar/components/Invoices/InvoiceFilters/InvoiceRangeDateFilterButton';
import { SearchInput } from '@ar/components/shared/SearchInput/SearchInput';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { DraftInvoiceCustomerFilterButton } from '@/ar/components/DraftInvoices/DraftInvoiceFilters/InvoiceCustomerFilterButton';
import { useDraftInvoicesStore } from '@/ar/stores/DraftInvoicesStore';

const DEBOUNCE_SEARCH_QUERY_DELAY = 500;

export const DraftInvoiceFilters : React.FC = () => {
    const { updateSearchParams, resetPagination, searchParams } = useDraftInvoicesStore();
    const [searchQuery, setSearchQuery] = useState<string>(searchParams.invoice_number ?? '');
    const debounceSearchQuery = useMemo(() => debounce((query:string) => {
        if (query === '') {
            updateSearchParams({ invoice_number: undefined, page: 0 });
            return;
        }
        updateSearchParams({ invoice_number: query, page: 0 });
        resetPagination();
    }, DEBOUNCE_SEARCH_QUERY_DELAY), []);
    const handleQueryChange = (query: string = ''):void => {
        setSearchQuery(query);
        debounceSearchQuery(query);
    };
    return <div className="flex flex-col gap-3 py-6">
        <SearchInput onChange={(value):void => handleQueryChange(value as string)}
            value={searchQuery}
            onFocus={() => sendTrackingEvent(TrackingEvents.enterSearchInvoice)}
            inputProps={{
                placeholder: 'Search for invoice number',
            }
            }/>
        <div className="flex gap-2 flex-wrap">
            <DraftInvoiceCustomerFilterButton/>
            <InvoiceRangeDateFilterButton label={'Due Date'}
                initialEndDate={searchParams?.due_date_end ? new Date(searchParams?.due_date_end) : undefined}
                initialStartDate={searchParams?.due_date_start ? new Date(searchParams?.due_date_start) : undefined}
                onDateRangeChange={({ endDateUTC, startDateUTC }):void => {
                    const params = {
                        due_date_start: startDateUTC ?? undefined,
                        due_date_end: endDateUTC ?? undefined,
                        page: 0,
                    };
                    updateSearchParams(params);
                }}/>
            <InvoiceRangeDateFilterButton
                initialStartDate={searchParams?.invoice_date_start ? new Date(searchParams?.invoice_date_start) : undefined}
                initialEndDate={searchParams?.invoice_date_end ? new Date(searchParams?.invoice_date_end) : undefined}

                label={'Issue Date'}
                onDateRangeChange={({ startDateUTC, endDateUTC }):void => {
                    const params = {
                        invoice_date_start: startDateUTC ?? undefined,
                        invoice_date_end: endDateUTC ?? undefined,
                        page: 0,
                    };
                    updateSearchParams(params);
                }}/>

        </div>
    </div>;
};
