import React, { useEffect, useMemo } from 'react';
import { useGetCustomersInfinite } from '@ar/hooks/queries/CustomerQueries.hook';
import { AutocompletePopover, PopoverAutoCompleteItem } from '@notch-ordering/ui-components';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { FilterLabel } from '@ar/components/shared/FilterLabel/FilterLabel';
import { InvoiceFilterLoadingButton } from '@ar/components/Invoices/InvoiceFilters/InvoiceFilterLoadingButton';
import { getGpoId } from '@v2/utils/GPOUtils';
import { FilterPopoverButton } from '@/components/shared/FilterButton';
import { useDraftInvoicesStore } from '@/ar/stores/DraftInvoicesStore';

const DEFAULT_CUSTOMERS_LIMIT = 1000;
export const DraftInvoiceCustomerFilterButton : React.FC = () => {
    // Store variables
    const { supplierLoginData } = useSupplierStore();
    const { searchParams, updateSearchParams } = useDraftInvoicesStore();

    // API variables
    const supplierID = supplierLoginData?.supplier_id;
    const gpoId = getGpoId(supplierLoginData);
    const { data: customersResults, hasNextPage, fetchNextPage, isFetching, isLoading } = useGetCustomersInfinite({ supplierID, gpoId, limit: DEFAULT_CUSTOMERS_LIMIT });
    const customers = customersResults?.pages?.flatMap((page) => page.customers) ?? [];

    // Other variables
    const hasCustomerSelected = !!searchParams?.customer_id;
    // Returns the name of the customer with the specified ID, or an empty string if no customer is found.
    const getCustomerName = (customerID:string):string => customers.find((customer) => customer.id === customerID)?.name ?? '';

    // List of customer items that are filtered based on the search query and mapped to a list of PopoverItem objects with click handlers and keyboard navigation support.
    const customerItems = useMemo<PopoverAutoCompleteItem[]>(() => customers
        .map((customer) => ({
            key: customer.id,
            searchableField: customer.name,
            label: <FilterLabel label={customer.name} isChecked={searchParams?.customer_id === customer.id}/>,
            onClick: (): void => {
                const newSearchParams = { customer_id: searchParams?.customer_id === customer.id ? '' : customer.id, page: 0 };
                updateSearchParams(newSearchParams);
            },
        })), [searchParams?.customer_id, customers]);

    // load all customer by splitting request into pages
    useEffect(() => {
        if (hasNextPage && !isFetching) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetching]);
    if (isLoading) {
        return <InvoiceFilterLoadingButton/>;
    }
    return (
        <AutocompletePopover items={customerItems}
            placement={'bottom-start'}
            className="max-h-80 w-[200px] overflow-auto"
            button={<FilterPopoverButton
                isChecked={hasCustomerSelected}
                label={hasCustomerSelected ? `Customer is ${getCustomerName(searchParams?.customer_id ?? '')}` : 'Customer'}
                handleClearFilter={() => {
                    updateSearchParams({ customer_id: undefined, page: 0 });
                }}/>}/>

    );
};
